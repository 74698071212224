require('./bootstrap');

$(document).on('keydown', 'input[pattern]', function () {
    const input = $(this);
    const oldVal = input.val();
    const regex = new RegExp(input.attr('pattern'), 'g');
    setTimeout(function () {
        const newVal = input.val();
        if (!regex.test(newVal)) {
            input.val(oldVal);
        }
    }, 1);
});
